import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Link,
  useNavigate,
} from "react-router-dom";
import { signinThunk } from "../../../redux/auth/authThunk";
import { setopenSegment } from "../../../redux/dashboard/dashboardSlicer";
import { RouterConfig } from "../../../routerConfig";
import { notify } from "../../../utils/helper";
import "../style.scss";

export const PasswordCriteria = (
  <ul>
    <li>At least 8 characters.</li>
    <li>
      Must contain at least 1 Uppercase Letter
    </li>
    <li>
      Must contain at least 1 Lowercase Letter
    </li>
    <li>Must contain at least 1 Number Letter</li>
    <li>
      Must contain at least 1 Special Characters
    </li>
  </ul>
);

export const LoginFormNew = ({ setFormView }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [btndisabled, setbtndisabled] =
    useState(true);
  const navigate = useNavigate();

  const onValuesChange = (
    changedValues,
    allValues
  ) => {
    if (
      allValues.email !== undefined &&
      allValues.password !== undefined &&
      allValues.email !== "" &&
      allValues.password !== ""
    ) {
      setbtndisabled(false);
    } else {
      setbtndisabled(true);
    }
  };
  const onFinish = (values) => {
    dispatch(signinThunk(values)).then((res) => {
      if (res.payload) {
        notify("success", "Login Successful!");

        if (
          // res?.payload?.totalLogin < 2 &&
          res?.payload?.role ===
            "pharmacy_admin" &&
          res?.payload?.segmentSelected === null
        ) {
          setTimeout(() => {
            dispatch(setopenSegment(true));
          }, 1500);
        }
        form.resetFields();
        window.analytics.identify(
          res.payload.id,
          {
            lastLogin: res.payload.lastLogin,
            totalLogin: res.payload.totalLogin,
            firstLogin: res.payload.firstLogin,
            email: res.payload.email,
            roleId: res.payload.roleId,
          }
        );
        if (
          res.payload.role === "pharmacy_admin"
        ) {
          window.fbq(
            "trackCustom",
            "PA Transition",
            {
              id: res.payload.id,
              email: res.payload.email,
              userType: res.payload.group,
              name: res.payload.name,
              surname: res.payload.surname,
              role: res.payload.role,
              gphcNumber: res.payload.gphcNumber,
              device: "web",
            }
          );
        }
      }
    });
  };

  return (
    <div
      className="d-flex flex-column mx-auto mt-4 login_new locum_signup_new"
      style={{ maxWidth: "534px", width: "100%" }}
    >
      <div className="header text-center">
        <div className="heading2">
          Welcome Back
        </div>
        <div className="t1 sub_heading">
          Login to your account!
        </div>
      </div>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        onValuesChange={onValuesChange}
        autoComplete="off"
        className="login_newform locum_signup_form_new"
      >
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              type: "email",
              message: "Please Enter your email!",
            },
          ]}
        >
          <Input
            onBlur={(e) =>
              form.setFieldsValue({
                email: e.target.value.trim(),
              })
            }
            className="text-input-field  p-2 t1"
            placeholder="Enter address"
            maxLength={50}
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          className="position-relative"
        >
          <Input.Password
            onBlur={(e) =>
              form.setFieldsValue({
                password: e.target.value.trim(),
              })
            }
            placeholder="Enter password"
            className="text-input-field  t1"
            maxLength={50}
          />
        </Form.Item>
        {/* <div className="d-flex align-items-center mb-4 justify-content-end">
                    <span className="p2 text-decoration-none  cursor-pointer" style={{ color: '#51C2BC' }} onClick={() => setFormView('forgotPass')} >Forgot Password?</span>
                </div> */}

        <div
          className="p2 d-flex align-items-center justify-content-between form_bottom"
          style={{
            color: "#000",
            paddingTop: "8px",
          }}
        >
          <span
            className="form_bottom"
            style={{ color: "#000" }}
          >
            Don’t have an account?
            <Link
              to={RouterConfig.signup}
              className="text-decoration-none ms-1"
              style={{ color: "#09988D" }}
            >
              {" "}
              Create one
            </Link>
          </span>
          <Form.Item className="mb-0">
            <Button
              disabled={btndisabled}
              className=" custom_btn auth_button button"
              type="primary"
              htmlType="submit"
              block
            >
              Login
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
