import { Input, Space, Typography } from "antd";
import React from "react";
import CustomIconButton from "../../../../../../components/UI/IconButton/IconButton";
import DeleteIcon from "../../../../../../components/UI/icons/DeleteIcon";
const AddQuestionCard = ({
  data,
  index,
  staffId,
  handleAnswerInput,
  toggleshowDeleteQuestion,
  hasError,
}) => {
  return (
    <div className="perform-question-card add-question">
      <div style={{ display: "flex", marginBottom: 16 }}>
        <Space direction="horizontal" style={{ marginBottom: 8 }}>
          <span className="bgGreen">{index + 1}</span>
          <Typography.Title level={5}>{data?.question}</Typography.Title>
        </Space>

        <CustomIconButton
          icon={<DeleteIcon />}
          style={{ marginLeft: "auto", backgroundColor: "none !important" }}
          onClick={toggleshowDeleteQuestion}
        />
      </div>

      <div className="input-wrap">
        <label htmlFor="answer">Answer</label>
        <Input.TextArea
          rows={2}
          placeholder="Your Text..."
          value={data?.answer}
          onChange={(e) => handleAnswerInput(e.target.value, index)}
        />
        {hasError && <p className="text-danger">Please enter answer</p>}
      </div>
    </div>
  );
};

export default AddQuestionCard;
